import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { applyStyleModifiers } from "styled-components-modifiers"
import { lighten, darken } from "polished"

import { Type, Color } from "../"

const BUTTON_MODIFERS_CONFIG = {
  primary: () => `
    background: ${Color.primary};
    color: ${Color.white};

    &:hover {
      background: ${darken(0.1, Color.primary)};
    }
    `,

  success: () => `
    background: ${Color.green};
    color: ${Color.white};

    &:hover {
      background: ${darken(0.1, Color.green)};
    }
  `,

  default: () => `
    color: ${lighten(0.2, Color.grey)} !important;

    &:hover {
      color: ${Color.grey} !important;
    }
  `,

  danger: () => `
    color: ${Color.white};
    background: ${Color.red};

    &:hover {
      background: ${lighten(0.1, Color.red)};
    }
  `,

  small: () => `
    padding: 0.5rem 1rem
    font-size: 0.9rem;
    `,

  block: () => `
    width: 100%;
    text-align: center;
  `,
}

const buttonStyles = css`
  padding: 0.5rem 3rem;
  font-size: 1rem;
  border: 0;
  font-family: ${Type.header};
  font-weight: normal;
  border: 1px solid ${Color.white};
  transition: all 0.4s ease;

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  ${applyStyleModifiers(BUTTON_MODIFERS_CONFIG)};
`

export const Button = styled.button`
  ${buttonStyles}
`

export const ButtonLink = styled(Link)`
  ${buttonStyles};
`
