import React from "react"
import * as axios from "axios"

import {
  Form,
  Input,
  Textarea,
  AlignRight,
  Button,
  Mutation,
  ErrorAlert,
  Alert,
} from "../../utilities"

const sendToLambda = data =>
  axios.post(`/.netlify/functions/subscribeuser`, { ...data })

export const MailchimpForm = () => (
  <Mutation mutation={sendToLambda} id="mailchimp">
    {(sendMailchimp, { error, loading, result }) => (
      <Form
        state={{
          email_address: "",
          FNAME: "",
          LNAME: "",
          MMERGE3: "",
        }}
        required={["email_address", "FNAME", "LNAME"]}
        onSubmit={sendMailchimp}
      >
        {({ state, handleChange }) => (
          <>
            <ErrorAlert error={error} />
            {error === null && result !== null && (
              <Alert modifiers={["success"]}>
                You have successfully been subscribed to our mailing list!
              </Alert>
            )}
            <Input
              type="email"
              name="email_address"
              showLabel={false}
              value={state.email_address}
              onChange={handleChange}
            >
              Email Address (required)
            </Input>

            <Input
              name="FNAME"
              showLabel={false}
              value={state.FNAME}
              onChange={handleChange}
            >
              First Name (required)
            </Input>

            <Input
              name="LNAME"
              showLabel={false}
              value={state.LNAME}
              onChange={handleChange}
            >
              Last Name (required)
            </Input>

            <Textarea
              name="MMERGE3"
              showLabel={false}
              minHeight="150px"
              value={state.MMERGE3}
              onChange={handleChange}
            >
              Interests
            </Textarea>

            <AlignRight>
              <Button modifiers={["danger"]} disabled={loading}>
                {loading ? "Loading..." : "Subscribe"}
              </Button>
            </AlignRight>
          </>
        )}
      </Form>
    )}
  </Mutation>
)
