import { createGlobalStyle } from "styled-components"
import { normalize } from "polished"

import { Color, Type, Media } from "../../utilities"

export const GlobalStyles = createGlobalStyle`
  ${normalize()};

  @import url('https://fonts.googleapis.com/css?family=Baskervville|Roboto+Slab&display=swap');

  body {
      background-color: ${Color.black};
      font-family: ${Type.text};
      font-size: ${Type.basesize};
      color: ${Color.white};
      line-height: 150%;
  }

  p {
      margin: 0;
      line-height: 150%;
      margin-bottom: 1em;
  }

  h1, h2, h3, h4, h5, h6, h7, h8, h9 {
      margin: 0;
      padding: 0;
      font-weight: 500;
      margin-bottom: 1em;
      font-family: ${Type.header};
  }

  h1 {
      font-size: 3rem;
  }

  h2 {
      font-size: 2.5rem;
  }

  h3 {
      font-size: 2rem;
  }

  h4 {
      font-size: 1.5rem;
  }

  .alignright {
    display: block;
    margin-left: auto;
    float: right;
    }

  .alignleft {
    display: block;
    margin-right: auto;
    float: left;
  }

  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  ${Media.below.mobile`
      .m-hidden {
          display: none;
      }
  `}

  ${Media.below.tablet`
      .mt-hidden {
          display: none;
      }
  `}

  
  ${Media.above.tablet`
      ${Media.below.desktop`
          .t-hidden {
              display: none;
          }
      `}
  `}
`
