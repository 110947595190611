import React from "react"
import { Link } from "gatsby"

import { Section, SmallSectionWrap } from "../Section"
import { AlignCenter } from "../../utilities"

export const LayawaySection = () => (
  <Section modifiers={["black", "paddSm"]}>
    <AlignCenter>
      <SmallSectionWrap>
        <h2>Layaways are Welcome</h2>
        <p>
          Need to split your order into multiple payments? No problem! A simple
          20% earnest money deposit will hold your item for you.-acf
          <br />
          <br />
          You can then pay it off in easy installments that fit your budget.
          <br />
          <br />
          <Link to="/terms">Read Terms Here</Link>
        </p>
      </SmallSectionWrap>
    </AlignCenter>
  </Section>
)
