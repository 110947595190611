import React from "react"
import styled from "styled-components"
import { linearGradient } from "polished"
import { applyStyleModifiers } from "styled-components-modifiers"

import { Color, Wrapper } from "../../utilities"

const SECTION_MODIFIERS_CONFIG = {
  black: () => `
    background: ${Color.black};
    color: ${Color.white};
  `,
  paddSm: () => `
    padding: 2rem 0;
  `,
}

export const StyledSection = styled.section`
  padding: 4rem 0;
  font-size: 16px;

  ${linearGradient({
    colorStops: [`${Color.primary} 0%`, `${Color.grey} 100%`],
    toDirection: "to right",
    fallback: Color.primary,
  })}

  background-image: ${props =>
    props.backgroundImage ? `url(${props.backgroundImage})` : "none"};
  background-size: cover;
  color: ${Color.white};

  a {
    font-weight: 100 !important;
    text-decoration: underline !important;
    color: ${Color.white};
    cursor: pointer;

    &:hover {
      color: ${Color.red};
    }
  }

  ${applyStyleModifiers(SECTION_MODIFIERS_CONFIG)}
`

export const Section = ({ children, ...props }) => (
  <StyledSection {...props}>
    <Wrapper>{children}</Wrapper>
  </StyledSection>
)

export const SmallSectionWrap = styled.div`
  width: 400px;
  max-width: 90%;
  margin: auto;
`
