import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { lighten } from "polished"

import { Color, Wrapper, Type, Media, Button, Toggle } from "../../utilities"

import logo from "../../../images/logo.jpg"
import background from "../../../images/header-background.jpg"
import current from "../../../images/current-replace.png"
import previous from "../../../images/previous-replace.png"

const StyledHeader = styled.header`
  background: ${Color.black};
  padding: 0;
  color: ${Color.white};
`

const StyledWrapper = styled(Wrapper)`
  text-align: center;

  .logo {
    max-width: 100%;
  }
`

const ZLink = styled(Link)`
  z-index: 1000;
`

/**
 * background-image: url(${background});
  background-size: cover;
 */
const HeaderWrapper = styled.div`
  padding-bottom: 1rem;
  background-color: ${Color.primary};
`

const NavBar = styled.nav`
  background: ${Color.black};
  margin-top: 3px;
`

const NavWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Media.below.desktop`
    flex-wrap: wrap;
  `}

  ${Media.below.tablet`
    display: block;
    overflow: hidden;
    height: ${props => (props.mobileNav ? "auto" : "40px")}
  `}
`

const NavButton = styled(Button)`
  display: none;
  height: 40px;

  ${Media.below.tablet`
    display: block;
  `}
`

const NavLink = styled(Link)`
  font-size: 1rem;
  color: ${Color.white};
  text-decoration: none;
  display: block;
  padding: 0.5rem;
  border-bottom: 3px solid ${Color.black};
  transition: all 0.3s ease;
  flex: 1;
  text-align: center;

  &:hover {
    border-bottom: 3px solid ${Color.white};
  }

  ${Media.below.desktop`
    padding: 0.5rem;
  `}
`

const HeaderHead = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;

  ${Wrapper} {
    display: flex;
    justify-content: space-between;
  }
`
const HeadNav = styled.nav``

const HeadNavLink = styled(Link)`
  color: ${Color.white};
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem;

  &:hover {
    text-decoration: underline;
  }

  ${Media.below.tablet`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
  `}
`

const BannerContainer = styled.div`
  margin-top: 5px;
  ${Wrapper} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  ${Media.below.tablet`
    ${Wrapper} {
      grid-template-columns: 1fr;
    }
  `}
`

const BannerText = styled.div`
  display: block;
  width: 100%;
  background-color: ${Color.red};
  color: ${Color.white};
  font-family: ${Type.header};
  text-align: center;
  text-decoration: none;
  padding: ${props => (props.large ? "1rem" : "0.5rem")};
  border-top: 2px solid ${Color.white};
  border-bottom: 2px solid ${Color.white};
`

const BannerItem = styled(Link)`
  height: ${props => (props.large ? "500px" : "auto")};
  display: block;
  text-decoration: none;

  ${Media.below.tablet`
    height: ${props => (props.large ? "200px" : "auto")}
  `}

  &:hover {
    ${BannerText} {
      background: ${lighten(0.1, Color.red)};
    }
  }
`

const ItemBox = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  background: ${Color.primary};
  background-size: cover;
  background-repeat: no-repeat;

  &.current {
    background-image: url(${current});
  }
  &.previous {
    background-image: url(${previous});
  }
`

const getLink = ({ object_slug, url }) => {
  const urlObject = url.split("/")
  return urlObject[3] === "" ? "/" : object_slug
}

const Header = ({ site = {}, large = false, navs = [] }) => {
  const topMenu = navs.find(nav => nav.node.name === "top_menu")
  const bottomMenu = navs.find(nav => nav.node.name === "bottom_menu")
  const midNav = navs.find(nav => nav.node.name === "mid_nav")

  return (
    <HeaderWrapper>
      <HeaderHead>
        <Wrapper>
          <div>Phone: {site.phone}</div>
          <HeadNav>
            {topMenu.node.items.map(item => (
              <HeadNavLink to={getLink(item)}>{item.title}</HeadNavLink>
            ))}
          </HeadNav>
        </Wrapper>
      </HeaderHead>
      <StyledHeader>
        <StyledWrapper>
          <ZLink to="/">
            <img src={logo} className="logo" />
          </ZLink>
        </StyledWrapper>
      </StyledHeader>
      <NavBar>
        <Toggle>
          {({ on, toggle }) => (
            <NavWrapper mobileNav={on}>
              <NavButton modifiers={["block", "primary"]} onClick={toggle}>
                {!on ? "Open " : "Close "} Navigation
              </NavButton>
              {midNav.node.items.map((item, key) => (
                <NavLink to={getLink(item)} key={key}>
                  {item.title}
                </NavLink>
              ))}
            </NavWrapper>
          )}
        </Toggle>
      </NavBar>
      <BannerContainer>
        <Wrapper>
          {bottomMenu.node.items.map(item => (
            <BannerItem large={large} to={getLink(item)}>
              <ItemBox className={item.classes}>
                <BannerText large={large}>{item.title}</BannerText>
              </ItemBox>
            </BannerItem>
          ))}
        </Wrapper>
      </BannerContainer>
    </HeaderWrapper>
  )
}

export default Header
