import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Section } from "../Section"
import { Grid, Col } from "../../utilities"
import { MailchimpForm } from "../MailchimpForm"

export const ContactSection = () => (
  <Section>
    <StaticQuery
      query={graphql`
        query ContactSectionQuery {
          allWordpressWpConfig(filter: { slug: { eq: "contact" } }) {
            edges {
              node {
                title
                content
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const content = data.allWordpressWpConfig.edges[0].node
        return (
          <Grid modifiers={["gap"]}>
            <Col>
              <h2>{content.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: content.content }}></div>
            </Col>
            <Col>
              <h2>Join Our Mailing List</h2>
              <MailchimpForm />
            </Col>
          </Grid>
        )
      }}
    />
  </Section>
)
