import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { linearGradient } from "polished"

import Header from "../Header"
import Footer from "../Footer"

import { GlobalStyles } from "../../elements"
import { Color, SEO } from "../../utilities"

const Body = styled.div`
  line-height: 200%;
  font-size: 18px;
  ${linearGradient({
    colorStops: [`${Color.primary} 0%`, `${Color.grey} 100%`],
    toDirection: "to right",
    fallback: Color.primary,
  })}

  a {
    color: ${Color.white};
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: 18px;
    line-height: 200%;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`

const isHome = ({ uri }) => uri === "/"

export const fixContentLinks = content => {
  const regex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/gi
  let link
  while ((link = regex.exec(content)) !== null) {
    const replaceLink = link[2].replace(
      process.env.GATSBY_API_URL,
      process.env.GATSBY_WEB_URL
    )
    content = content.replace(link[2], replaceLink)
  }

  return content
}

const Layout = ({ children, uri }) => (
  <StaticQuery
    query={graphql`
      query SiteDataQuery {
        site {
          siteMetadata {
            title
            description
            phone
            address
            alt_phone
            email
          }
        }
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              name
              items {
                title
                url
                object_slug
                classes
              }
            }
          }
        }
      }
    `}
    render={({ site, allWordpressWpApiMenusMenusItems }) => (
      <>
        <GlobalStyles />
        <Header
          site={site.siteMetadata}
          large={isHome({ uri })}
          navs={allWordpressWpApiMenusMenusItems.edges}
        />
        <Body>{children}</Body>
        <Footer site={site.siteMetadata} />
      </>
    )}
  ></StaticQuery>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
