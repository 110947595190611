import React from "react"
import styled from "styled-components"
import { darken } from "polished"

import * as moment from "moment"

import footerimg from "../../../images/footer-logo.jpg"

import { Color, Wrapper, Flex, ConvertNewLineBR, Media } from "../../utilities"

const StyledFooter = styled.div`
  color: ${Color.white};
  padding: 2rem 0 3rem 0;

  h4 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  a {
    text-decoration: none;
    color: ${Color.white};
    &:hover {
      text-decoration: underline;
      color: ${Color.white};
    }
  }

  ${Flex} {
    justify-content: center;

    ${Media.below.tablet`
      display: block;
    `}
  }
`

const SiteMeta = styled.div`
  flex: 0 !important;
  flex-basis: 350px !important;

  ${Media.below.tablet`
    text-align: center;
  `}
`

const FooterLogo = styled.div`
  flex: 0 !important;
  width: 100%;

  ${Media.below.tablet`
    img {
      max-width: 100%;
    }
  `}
`

const BottomFooter = styled.div`
  font-size: 0.9rem;
  color: ${darken(0.2, Color.white)};
  text-align: center;
  padding: 1.5rem 0;
`

const Footer = ({ site = {} }) => {
  return (
    <StyledFooter>
      <Wrapper>
        <Flex>
          <SiteMeta>
            <h4>Contact Us</h4>
            <p>
              <ConvertNewLineBR>{site.address}</ConvertNewLineBR>
              <br />
              {site.phone} weekdays EST
              <br />
              or {site.alt_phone} evenings.
              <br />
              {site.email}
            </p>
          </SiteMeta>
          <FooterLogo>
            <img src={footerimg} />
          </FooterLogo>
        </Flex>
        <BottomFooter>
          &copy; {`${moment().format("YYYY")} `} {site.description}. All Rights
          Reserved
        </BottomFooter>
      </Wrapper>
    </StyledFooter>
  )
}

export default Footer
